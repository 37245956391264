/*
 *  Slider
 *  scss - imported on index.scss
 *
 */

.slider {
  position: relative;
  padding: 0 15px;

  z-index: -100;

  @media screen and (max-width: $screen-sm) {
    padding: 0 0;
  }
  @media screen and (min-width: $screen-lg) {
    padding: 0 40px;
  }
  @media screen and (min-width: 1500px) {
    margin-top: 20px;
  }

  

  .tudo-row-slider {
    padding: 0 30px 90px 30px;
    @media screen and (max-width: $screen-sm) {
      padding: 50px 10px 50px 10px;
    }
    @media screen and (min-width: 1500px) {
      min-height: 700px;
    }
  }

  .container {
    max-width: 100%;
  }

  .slider-wrapper {
    position: relative;
    min-height: 600px;
    max-width: 100%;
    clip-path: url(#banner-mask);
    background-color: #00abeb;

    @media screen and (min-width: 1500px) {
      min-height: 700px;
    }
    @media screen and (max-width: 992px) {
      margin-top: 50px;
    }
    
    @media screen and (max-width: 500px) {
      margin-top: 30px;
    }


    .linhas-banner {
      position: fixed;
      right: -70px;
      top: 90px;
      mix-blend-mode: luminosity;
  }

    .fundo-nuvens {
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      min-height: 600px;
      object-fit: cover;
      @media screen and (min-width: $screen-sm) {
        // bottom: -20px;
      }
    }

    .slider-tit{
      margin-top:80px;
      font-weight: 800;
      font-size: 5.1rem;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: left;
      text-shadow: -1px -1px rgba(0, 0, 0, .25);
      line-height: 65px;

      @media screen and (max-width: $screen-md) {
          line-height:65px;
          margin-top: 70px;
      }
      @media screen and (max-width: $screen-sm) {
        text-align: center;
        line-height: 41px;
        margin-top: -50px;
        font-size: 4.2rem;
    }
      
    }

    .slider-sub-tit {
      font-size: 1.9rem;
      font-weight: 700;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: left;
      line-height: 33px;
      text-shadow: -1px -1px rgba(0, 0, 0, .25);

      @media screen and (max-width: $screen-sm) {
        text-align: center;
        line-height: 23px;
  
      }
    }

    .happy {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      margin: auto;
      max-height: 570px;
      opacity: 1;
      @media screen and (max-width:  900px) {
       opacity: 0;
      }
      @media screen and (max-width:  767px) {
        display: block;
        opacity: 1;
        left: -100px;
        right: unset;
      }
      @media screen and (max-width:  620px) {
        display: none;
      }
      @media screen and (min-width:  1500px) {
        max-height: 650px;
      }
    }

    .col1-ajust-slider {
      position: relative;
      z-index: 10;
      @media screen and (max-width: $screen-md) {
        // right: 60px;
        // bottom: 10px;
      }
      @media screen and (max-width: 900px) {
        right: 0px;
        bottom: 10px;
        order: 1;
        margin-top: none;
        padding-bottom: 0px;
      }
      @media screen and (max-width: $screen-sm) {
        order: 3;
        padding-bottom: 40px;
        // margin-top: 25px;
      }
    }

    .toilet {
      position: relative;
      right: 30px;
      top: 80px;
      @media screen and (max-width: $screen-md) {
        right: -10px;
      }
      @media screen and (max-width: 900px) {
        right: 50px;
      }
      // @media screen and (max-width:  $screen-sm) {
      //   top: 10px;
      //   right: 35px;
      // }
      @media screen and (min-width:  0) and (max-width:  $screen-sm) {
        width: 240px;
        top: -30px;
        right: 18px;
      }

      .caixa-pop-up{
        position: absolute;
        z-index: 100;
        top: 25px;
        left: 34px;
        opacity: 0;
      }
    } 

    .col-vazia {
      order: 2;
    }

    .pai-toilet {
      z-index: 10;
      order: 3;
      @media screen and (max-width: $screen-md) {
       padding-bottom: 70px;
       order: 3;
       margin-top: unset;
      }
      @media screen and (max-width: $screen-sm) {
        padding-bottom: 10px;
        order: 1;
        margin-top: 20px;
        transform: scale(0.9);
    padding-bottom: 0;

       }
    }

    .toilet-img {
      position: absolute;
      right: calc(22vw - 200px);
      top: 92px;
      @media screen and (max-width: $screen-md) {
         right: 0px;
      }
      @media screen and (max-width: 900px) {
        right: 15px;
      }
      // @media screen and (max-width:  $screen-sm) {
      //   width: 200px;
      //   top: 30px;
      //   right: 90px;
      // }
      @media screen and (min-width:  0) and (max-width:  $screen-sm) {
        width: 200px;
        top: 20px;
        // right: calc(2vw * 3);
        right: 0;
        left: 90px;
        margin: auto;
      }
    }

  }
}

.pop-up-global .des-pop-up {
  position: absolute;
  padding: 12px 15px 29px 15px;
  top: 0;
  font-weight: 800;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  min-width: 200px;
}

#main-swiper {
  
  .swiper-wrapper {
    padding-left: 0;

    @media screen and (max-width: 550px) {
     margin-top: 35px;
    }
  }
  .swiper-container {
    width: 100%;
    height: 100%;
    max-height: 150px;
  }

  .swiper-slide {

    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;

    text-align: left;
    font-size: 18px;

    p {
      max-width: 350px;
      font-size: 1.8rem;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: left;
      line-height: 33px;
      text-shadow: 1px 1px rgba(0, 0, 0, .25);
      font-weight: 500;

      @media screen and (max-width: $screen-sm) {
        text-align: center;
        width: 100%;
        max-width: none;
      }
      

      img{
        margin-right: 7px;
      }
    }

  }
}

.change-lang {
  position: absolute;
  right: -197px;
  // top: 45%;
  top: -18px;
  height: 45px;
  background-color: #DDEDF3;
  padding: 0 20px;
  border-bottom-left-radius: 22px;
  border-top-left-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: .7s;
  &:hover{
    right: 0;
    transition-delay: 0s;
    
  }
  @media screen and (max-width: $screen-md) {
    top: -25px;
  }
  @media screen and (max-width: $screen-sm) {
    top: -15px;
  }

  @media screen and (max-width: 550px) {
    top: -27px;
  }

  img {
    position: relative;
    right: 5px;
  }



  button {
    height: 24px;
    font-weight: 700;
    font-size: 15px;
    color: $primary;
    letter-spacing: 0.88px;
    text-align: left;
    border: none;
    background: none;
    border-right: 1px solid $primary;
    &:hover{
     color: $orange;
    }
    &:disabled{ 
      pointer-events: none;
      opacity: .4;
    }
    &:last-of-type {
    border-right: none;
      
    }
  }
}

#caixa-base-pop {
  opacity: 0;
}