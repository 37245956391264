/*
 *  TOP-HEADER
 *  scss - imported on index.scss
 *
 */

.top-header {

  display: flex;
  justify-content: flex-end;
  min-height: 165px;

  @media screen and (max-width: $screen-md) {
    min-height: 140px;
  }

  @media screen and (max-width: $screen-sm) {
    min-height: 130px;
  }

  @media screen and (max-width: 400px) {
    min-height: 115px;
  }

  @media screen and (max-width: 730px) {
    display: block;
  }


  .novo-icon {
    position: absolute;
    top: 27px;
    left: -30px;
    background-color: #D6A229;
    color: #fff;
    transform: rotate(-45deg) scale(1.3) translate(-2px, 0px);

    font-weight: 900;
    text-align: center;
    min-width: 150px;
    height: 22px;

    


    @media screen and (min-width: $screen-lg) {
      transform: rotate(-45deg) scale(1.7) translate(-1px, 9px);
    }

    @media screen and (max-width: $screen-md) {
      transform: rotate(-45deg) scale(1.3) translate(-2px, 0px);
    }

    @media screen and (max-width:400px) {
      transform: rotate(-45deg) scale(1.1) translate(-1px, -6px);
    }

    p{
      position: relative;
      bottom: 3px;
      font-size: 17px;
    }
  }

  .logo {
    display: inline-block;
    position: absolute;
    left: 100px;
    top: 50px;
    @media screen and (max-width: $screen-lg) {
      transform-origin: left top;
      transform: scale(0.9);
      left: 72px;
    }
    @media screen and (max-width: $screen-md) {
      transform-origin: left top;
      transform: scale(0.85);
      left: 66px;
      top: 47px;
    }
    @media screen and (max-width: 400px) {
      transform-origin: left top;
      transform: scale(0.8);
      left: 54px;
      top: 33px;
    }
  }

  .contact-bt {
    position: relative;
    top: 61px;
    margin-right: 50px;
    margin-left: 20px;
    @include gradientBt;
    padding: 0 20px;
    height: 45px;
    border-radius: 22.5px;
    line-height: 24px;
    font-weight: 700;
    font-size: 2.1rem;
    color: #FFFFFF;
    border: none;
    transition: all .3s ease-out;
    &:hover {
      background: #005e81;
    }


    @media screen and (max-width:$screen-md) {
      display: block;
      margin-right: 30px;

    }
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  

  .ele-bf {
    position: absolute;
    right: 146px;
    top: 22px;

    @media screen and (max-width: 768px) {
      right: 101px;
      top: 13px;
      transform: scale(0.8);
    }
    @media screen and (max-width: 400px) {
      right: 121px;
      top: 2px;
      transform: scale(.8);
    }
    
  }
}

.main-nav {
  position: relative;
  top: 61px;
  // padding-right: 270px;
  // @media screen and (max-width: $screen-md ) { padding-right: 220px; }
  // @media screen and (max-width:900px) { padding-right: 20px; }
  // @media screen and (min-width:1400px) { padding-right: 290px; }

  max-height: 60px;

  @media screen and (max-width: 900px) {
    margin-right: 30px;
  }
  @media screen and (max-width: 730px) {
     margin-right:unset;
  }

  ul.active {
    transform: translateX(0%);
  }

  ul{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;

    //transform-origin: 0% 0%;

    /*declarar o transition no ELE pai*/
    transition: transform .5s cubic-bezier(0.77,0.2,0.05,1.0);

    @media screen and (max-width:730px) { 
      display: flex;
      transform: translateX(100%);
      background: #fff;
      flex-direction: column;
      z-index: 200;
      position: absolute;
      right: 0;
      top: -61px;
      text-align: left;
      padding-top: 150px;
      width: 100%;
      height: 100vh;
      justify-content: space-evenly;
      padding-bottom: 100px;
      padding-left: 0;
    }

  }
    li {
      padding: 10px 10px;
      a {
        font-weight: 600;
        // font-size: calc(10px + 1vmin);;
        font-size:1.71rem;
        color: #005E81;
        letter-spacing: 1px;
        cursor: pointer;



        @media screen and (max-width:730px) {
          font-size: 2.71rem;
        }

        &:hover{
          text-decoration:none!important;
          &:after {
          transform: scaleX(1);
          }
        }

        &:after {
          position: relative;
          display: inline-block;
          bottom: 15px;
          content: "";
          width: 100%;
          height: 3px;
          background-color: $gold-dry;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform .15s ease-in;
        }


      }

      &:last-child a {
        color: $blue-dry;
      }
    }

  }

  .menu-mobile-active {
    overflow: hidden;
    padding-right: 15px;
  }

    .menu {
      position: absolute;
      right: 20px;
      top: 30px;
      transform: scale(.6);

      background-color: transparent;
      border: none;
      cursor: pointer;
      display: none;
      padding: 0;
      z-index: 999;

      @media screen and (max-width:730px) {
        display: flex;
      }
      @media screen and (max-width: 400px) {
        top: 12px;
        right: 0px;
      }
      @media screen and (max-width: 350px) {
        top: 12px;
        right: -15px;
      }
    }
    .line {
      fill: none;
      stroke:$blue-dry;
      stroke-width: 10;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 10;
    }
    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 10;
    }
    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 10;
    }
    .opened .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 10;
    }
    .opened .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 10;
    }
    .opened .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 10;
    }




    html[lang='de'] {


    }

  


