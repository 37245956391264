/*
 *  How to USE
 *  scss - imported on index.scss
 *
 */

.healt {
  position: relative;
  margin-top: 60px;

  .tit-healt {
    text-align: center;
    font-weight: 800;
    font-size: 6rem;
    color: $primary;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 90px;
    text-transform: uppercase;
    @media screen and (max-width: 575px) {
      margin-top: 30px;
      line-height: 45px;
      font-size: 4rem;
      margin-bottom: 10px;
    }
  }

  .sub-tit-healt {
    font-weight: 700;
    font-size: 3rem;
    color: $secondary;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 50px;
    line-height: 31px;
    @media screen and (max-width: 575px) {
      margin-bottom: 30px;
      line-height: 28px;
      font-size: 2.8rem;
    }
  }

  .fundo-produto {
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
    left: 50%;
    bottom: -30%;
    transform: translateX(-50%);
    z-index: -18;
  }

  .pai-dicas {
    @media screen and (max-width: 576px) {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}

.modal {
  transform: transale3d(0,0,0);
}
#modal-slide-1 {

  .modal-content {
    transform: transale3d(0,0,0);
    clip-path: url(#mask-heal-item);
    // padding: 45px;
    @include fade-in('450ms');
    border: none;
    border-radius: .9rem;

    .modal-header {
      // padding-top: 50px;
      padding: 90px 45px 0 45px;
      border: none;
      .close{
        position: relative;
        right: 37px;
        bottom: 80px;
        font-size: 9rem;
        color: #00ABEC;
        opacity: 1;
        padding: 0;
        transition: all .3s ease-out;

        &:hover {
          color: #005e81;
        }

      }
    }
    .modal-body{
      padding: 0px 45px;
    }
    .modal-footer {
      border: none;
      margin-top: 30px;
      justify-content: center;
      padding: 0 45px 70px 45px;

      .btn {
        background: linear-gradient(-270deg, #00ABEC 0%, #53C8F4 100%);
        height: 45px;
        border-radius: 22.5px;
        line-height: 24px;
        font-weight: 700;
        font-size: 2.1rem;
        color: #FFFFFF;
        border: none;
        padding: 0px 25px;
        transition: all .3s ease-out;
        &:hover {
          background: #005e81;
        }
      }
    }

  }
  .tit-item {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 20px;

    @media screen and (max-width:992px){
      font-size: 3.5rem;
      line-height: 34px;

    }
    @media screen and (max-width:600px){
      font-size: 3rem;
      line-height: 30px;
    }
    @media screen and (max-width:400px){
      font-size: 2.3rem;
      line-height: 27px;
    }

  
  }
  .img-item { 
    clip-path: url(#mask-heal-item);
    margin-bottom: 30px;
      img {
        width: 100%;
        height: 50vh;
        max-height: 320px;
        object-fit: cover;
      }
  }

  .text-item {
    @media screen and (min-width:992px){
      column-count: 2;
      column-gap: 60px;
    }


     p {
      font-size: 1.7rem;
     }
     h2 {
      margin: 15px 0;
      font-size: 3rem;
     }
     li {
      font-size: 1.7rem;
     }
  }

}

#healt-swiper {
  text-align: left;

  .swiper-wrapper {
    padding-left: 0;
    padding-bottom: 80px;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
    @media screen and (min-width: 575px) {
      text-align: left;
    }
  }     
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination {
    padding-top: 20px;
    border-top: 2px solid #AFD4E2;

    span {
      opacity: 1;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $blue-dry;
    }
    span.swiper-pagination-bullet-active  {
      background-color: $blue-dry-d;
    }
  }

  .img-item {
    max-height: 300px;
    clip-path: url(#mask-heal-item);
    img {
      width: 100%;
      max-height: 30vh;
      object-fit: cover;
    }
  }

  .data-item {
   margin: 5px 0;
  font-weight: 600;
  font-size: 1.2rem;
  color: #005E81;
  letter-spacing: 3px;

  }
  .tit-item {
    margin: 10px 0 5px 0;
    font-weight: 800;
    font-size: 2.7rem;
    color: #005E81;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      font-size: 2.3rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 2.1rem;
    }
    @media screen and (max-width: 550px) {
      font-size: 1.9rem;
    }
  }

  .text-item {
    font-weight: 400;
    font-size: 1.8rem;
    color: #005E81;
    line-height: 29px;
    @media screen and (max-width: 900px) {
      line-height: 23px;
    }
    @media screen and (max-width: 600px) {
      line-height: 20px;
    }
  }

  .button-item {
    @include gradientBt;
    height: 45px;
    border-radius: 22.5px;
    line-height: 24px;
    font-weight: 700;
    font-size: 2.1rem;
    color: #FFFFFF;
    border: none;
    padding: 0px 25px;
    margin-top: 15px;
    transition: all .3s ease-out;
    &:hover {
      background: #005e81;
    }
  }

}