/*
 *  Benefits
 *  scss - imported on index.scss
 *
 */

.beneficios {
  position: relative;

  .bfly {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: translate(185px, 60px);
  }

  .tit-benefits {
    text-align: center;
    font-weight: 800;
    font-size: 6rem;
    color: $primary;
    letter-spacing: 3px;
    text-align: center;

    @media screen and (min-width: 1500px) {
     margin-top: 140px;
  }

  }

  .sub-tit-benefits {
    font-weight: 700;
    font-size: 3rem;
    color: $secondary;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 60px;
    @media screen and (max-width: 575px) {
      margin-bottom: 30px;
      line-height: 32px;
    }
  }

  .fundo-produto {
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
    left: 50%;
    top: 16%;
    transform: translateX(-50%)rotate(180deg);
    z-index: -11;
  }
  .f2{
    top: 53%;
    transform: translateX(-50%);

  }

  .linhas-benefits {
    position: absolute;
    top:-33%;
    width: 113%;
    height: 100%;
    object-fit: cover;
    z-index: -1000;
    left: -70px;
    transform: scaleX(-1)rotate(5deg);
  
  }
  .l1 {

    @media screen and (max-width:$screen-sm) {
      transform: rotate(5deg)scale(1.4);
      width: 180%;
      top: -44%;
   }
  }
  .l2 {
    top: 0%;
    transform: rotate(5deg);
    @media screen and (max-width:$screen-sm) {
      transform: rotate(-20deg)scale(1.4);
      width: 180%;
   }
  }
  .l3 {
    top: 32%;
    @media screen and (max-width:$screen-sm) {
      transform: scaleX(-1) rotate(-21deg) scale(1.4);
      width: 180%;
   }

  }

}
  
@keyframes rotateBall{
  0%{transform:rotate(0deg)}
  100%{transform:rotate(359deg)}
}
@keyframes moveY {
  0%{transform:translateY(-100px)}
  50%{transform:translateY(0px)}
  100%{transform:translateY(-100px)}
}
@keyframes moveX {
  0%{transform:translateX(-130px)}
  50%{transform:translateX(0px)}
  100%{transform:translateX(-130px)}
}


@keyframes moveYcS {
    
  0%{transform:translateY(-60px) scale(.8);}
  50%{transform:translateY(0px) scale(.8);}
  100%{transform:translateY(-60px) scale(.8);}
}

@keyframes moveXcS {
    
  0%{transform:translateX(-60px) scale(.8);}
  50%{transform:translateX(0px) scale(.8);}
  100%{transform:translateX(-60px) scale(.8);}
}

#mask-ball1 {
  transform: transale3d(0,0,0);
  transform-origin: 211px 213px;
  // animation: rotateBall 6s infinite linear;
  //  animation-fill-mode: forwards;
  // animation-direction: normal;
}

#mask-ball {
  transform: transale3d(0,0,0);
  transform-origin: 211px 213px;
  //  animation: rotateBall 6s infinite linear;
  //  animation-fill-mode: forwards;
  //  animation-direction: normal;
  
}






.base-bene-item {
  border-radius: 7px;
  @include gradientBt;
  color: #fff;
  margin-bottom: 110px;
  padding: 55px 20px 50px 20px;
  box-shadow: 0 67px 55px -22px rgba(63,64,65,0.23), 0 43px 39px -14px rgba(68,76,79,0.20);

  @media screen and (max-width: $screen-md) {
    padding: 35px 20px 10px 20px;
   }
  @media screen and (max-width: 370px) {
    flex: 0 0 100%;
    margin-left: 0;
    max-width: 100%;
  }


  .pai-anima-top {
    position: absolute;
    top: -15px;
    z-index: -2;
    left: 30%;
    transform: transale3d(0,0,0);
    animation:moveX infinite 10s ease-in-out;
  }
  .pai-anima-right {
    position: absolute;
    top: 70%;
    z-index: -2;
    right: -18px;
    transform: transale3d(0,0,0);
    animation:moveY infinite 10s ease-in-out;
  }
  .pai-anima-right-top {
    position: absolute;
    top: 30%;
    z-index: -2;
    right: -18px;
    transform: transale3d(0,0,0);
    animation:moveY infinite 10s ease-in-out;
  }
  .pai-anima-left {
    position: absolute;
    top: 35%;
    z-index: -2;
    left: -18px;
    transform: transale3d(0,0,0);
    animation:moveY infinite 10s ease-in-out;
  }

  .ball-element {
    transform: transale3d(0,0,0);
    animation:rotateBall infinite 2.9s linear;
  }

  .img-animated-ball {
    position: absolute;
    width: 405px;
    height: 410px;
    transform: scale(.8) translate3d(0,0,0);
    clip-path: url(#mask-ball);
    background: $gold-dry;

    @media screen and (max-width:320px) {
        display: none;
     }

    img {
      position: relative;
      width: 100%!important;
      height: 100%!important;
      object-fit: cover;
      right: -60px;
      mix-blend-mode: luminosity;
    }
  }

  .iab1 {
    right: -185px;
    z-index: -3;
    bottom: -100px;
    animation:moveYcS infinite 10s ease-in-out;

  }

  .iab2 {
    left: -185px;
    z-index: -3;
    bottom: -100px;
    animation:moveYcS infinite 10s ease-in-out;
    animation-delay: 4s;

    img {
      position: relative;
      left: -60px;
    }

  }

  .iab3 {
    left: -90px;
    z-index: -3;
    bottom: -130px;
    animation:moveXcS infinite 10s ease-in-out;
    img {
      position: relative;
      left: 23px;
    }

  }

  .iab4 {
    left: unset;
    z-index: -3;
    right: -130px;
    bottom: -142px;
    animation:moveXcS infinite 10s ease-in-out;
    img {
      position: relative;
      left: -5px;
    }

  }



  .img-fundo-beneficios {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.1;
    mix-blend-mode: luminosity;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tit-beneficios {
    text-align: left;
    font-weight: 900;
    font-size: 4rem;
    color: #FFFFFF;
    letter-spacing: 1.33px;
    line-height: 45px;
    @media screen and (max-width: 575px) {
      line-height: 42px;
    }
  }
  .sub-tit-beneficios {
    text-align: left;
    font-weight: 800;
    font-size: 2.3rem;
    color: #FFFFFF;
    letter-spacing: 0.73px;
    margin-bottom: 60px;
      @media screen and (max-width: $screen-md) {
       margin-bottom: 30px;
      } 
      @media screen and (max-width: 575px) {
        line-height: 25px;
      }
    }

  .texto-beneficios {
    text-align: left;
    font-weight: 500;
    font-size: 2rem;
    color: #FFFFFF;
    letter-spacing: 0.67px;
    line-height: 26px;

    @media screen and (max-width: $screen-md) {
     padding: 15px;
    }

    @media screen and (max-width:$screen-sm) {
      padding: 0 15px;
   }


    p {
      margin-bottom: 25px;
    }
  }

  .img-src {
    transform: scale(1.2) translate(0px, 8px);
    @media screen and (max-width: $screen-md) {
      margin-bottom: 30px;
     }

  }

  .img-srcset {
    width: 100%;
    height: 100%;
    clip-path: circle(50% at 50% 50%);
    transform: scale(.9) translate(7px, -4px);
    max-height: 206px;
    min-height: 206px;

    @media screen and (max-width: $screen-lg) {
      clip-path: circle(45% at 50% 50%);
    }
    @media screen and (max-width: $screen-md) {
      transform: none;
      clip-path: none;
      max-height: 200px;
    }
    @media screen and (max-width:$screen-sm) {
      margin-bottom: 15px;
   }
   @media screen and (min-width: 1500px) {
    min-height: 256px;
    max-height: none;
  }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: $screen-md) {
        border-radius: 6px;
      }
    }
  }
}