/*
 *  GLOBAL
 *  Default settings, core styles and components
 *
 */

 @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600;700;800&display=swap');


*,*:before,*:after {
	box-sizing: border-box;
	-webkit-touch-callout:none;
	//user-select:none;
	-webkit-user-drag:none;
	outline:0!important
}

::selection {
    background-color: $blue-dry;
    color: $blue-dry-d;
}

html{
	font-size:62.5%;
	text-rendering:optimizeLegibility!important;
	-webkit-font-smoothing:antialiased!important;
    -webkit-text-size-adjust:100%;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (min-width: 0) {
        font-size:57.5%;
    }
    @media screen and (min-width: $screen-sm) {
        font-size:58.5%;
    }
    @media screen and (min-width: $screen-md) {
        font-size:60.5%;
    }
    @media screen and (min-width: $screen-lg) {
        font-size:62.5%;
    }
    @media screen and (min-width: $screen-xxl) {
        font-size:70%;
    }
    @media screen and (min-width: 1500px) {
        font-size:75%;
    }
    
}

body {
	margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Dosis', sans-serif;
    color: #005E81;

    
}

h1 {
	font-weight: 800;
	margin-top: 70px;
    margin-bottom: 0px;
    @media screen and (max-width: $screen-md) {
        margin-top: 40px;
    }
    @media screen and (max-width: $screen-sm) {
        margin-top: 30px;
    }
    @media screen and (max-width: 480px) {
        margin-top: 20px;
    }
}

.container {
    @media screen and (min-width: 1500px) {
       max-width: 1400px;
    }
}

.accelerated {
    -webkit-transform: transale3d(0,0,0);
    -moz-transform: transale3d(0,0,0);
    -ms-transform: transale3d(0,0,0);
    -o-transform: transale3d(0,0,0);
    transform: transale3d(0,0,0);
  
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s ;
  }

.img-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}



#root{
	// overflow-x: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.App{
	position: relative;
    overflow: hidden;
    z-index: 10;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    left: 0;
    top: 0;
}



//MIXIN´s

//Espera uns ml de seg e faz o fade e translate - MODAL
//@include fade-in('450ms');
@mixin fade-in($waitTime) {
    animation: 
        wait #{$waitTime},
        fade-in 315ms #{$waitTime} ease-in;
}

@keyframes wait {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes fade-in {
    0% { opacity: 0; transform: translateY(50px); }
    100% { opacity: 1; transform: translateY(0px); }
}