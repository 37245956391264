/*
 *  HELP - NAV
 *  scss - imported on index.scss
 *
 */

.help-nav {
  position: fixed;
  right: -100px;
  bottom: 73px;
  height: 45px;
  background-color: #DDEDF3;
  padding: 0 20px;
  border-bottom-left-radius: 22px;
  border-top-left-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
   transition: all 0s cubic-bezier(0.075, 0.82, 0.165, 1);
   transition-delay: .7s;
    z-index: 1000;

  &:hover{
    right: -20px;
    transition-delay: 0s;
    
  }
  img {
    position: relative;
    right: 5px;
  }
  .goto-top {
    cursor: pointer;
    margin-right: 7px;

    &:hover {
      img{
        filter: brightness(.6);
      }
    }


  }
  .goto-next {
    cursor: pointer;
    img{
      transform: rotate(180deg);
      &:hover {
        filter: brightness(.6);
      }
    }
  } 
}