/*
 *  PRODUCT
 *  scss - imported on index.scss
 *
 */

.produto {
  position: relative;
  .fundo-produto {
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  .tit-product {
    font-size: 6rem;
    color:$primary;
    letter-spacing: 3px;
    text-align: center;
  }

  .sub-tit-product {
    font-weight: 700;
    font-size: 3rem;
    color: $secondary;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 60px;
    @media screen and (max-width: $screen-md) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: $screen-sm) {
      line-height: 30px;
      margin-bottom: 0px;
    }
  }

  .title-product {
    margin: 25px 0 15px 0;
    font-weight: 400;
    font-size: 33px;
    color: #00ABEC;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
  }

  .desc-icon {
    padding-left: 40px;
    @media screen and (max-width: $screen-sm) {
     bottom: 40px;
    }
    &:before {
      content: url('../../files/produto/icon-mais.svg');
      position: absolute;
      top: 35px;
      left: 0;
    }
  }

  .desc-icon:nth-of-type(1) {
    @media screen and (max-width: $screen-sm) {
     order: 2;
     }
  }

  .desc-icon:nth-of-type(2) {
    @media screen and (max-width: $screen-sm) {
      margin-top: 40px;
     }
  }

  .desc-product {
    font-weight: 400;
    font-size: 1.8rem;
    color: $primary;
    letter-spacing: 0.8px;
    text-align: left;
    line-height: 28px;
    @media screen and (max-width: $screen-md) {
      line-height: 25px;
    }
  }
  .call-to-action {
    display: inline-block;
    text-transform: uppercase;
    background: linear-gradient(
-270deg
, #00ABEC 0%, #53C8F4 100%);
    padding: 3px 20px;
    height: 32px;
    border-radius: 22.5px;
    line-height: 24px;
    font-weight: 700;
    font-size: 2.1rem;
    color: #FFFFFF;
    border: none;
    transition: all .3s ease-out;
    text-decoration: none;

    &:hover {
      background: #005e81;
    }
}


  .product1-prototipo {
    position: relative;
    right: 20px;
    @media screen and (max-width: $screen-md) {
      transform: scale(1.3);
      top: 30px;
    }
    @media screen and (max-width:900px) {  transform: scale(1.2);
    @media screen and (max-width:  $screen-sm) {
      transform: scale(1);
    }
    @media screen and (max-width: 480px) {
      transform: scale(1)translate(18px, 3px);
    }

 }

  }
  .product2-prototipo {
    position: relative;
    bottom: 40px;
    right: 20px;
    max-width: 400px;
    @media screen and (max-width: $screen-md) {

      top: 20px;
      right: 0;
    }
  }

  // SCENE 3d

  .scene {
    position: relative;
    bottom: 50px;
    width:100%;
    height:100%;
    min-height: 420px;
    perspective: 70em;
    user-select: none;
    @media screen and (max-width: $screen-md) {
      bottom: 0;
      min-height: 450px;
      left: -80px;
      perspective-origin: 50% 20%;
      perspective: 100em;
    }
    @media screen and (max-width: $screen-sm) {
      transform: scale(.9);
      left: 0;
      }
      @media screen and (max-width: 440px) {
        transform: scale(.8);
        left:-12%;
        }
        @media screen and (max-width: 360px) {
          left:-26%;
      }
      @media screen and (max-width: 360px) {
        left:-26%;
    }
    @media screen and (min-width: $screen-md) {
      transform: scale(1);
      left: 0;
    }

    transform: scale(0.8);
    left: -12%;

  }
  
  .card3d {
    width:100%;
    height:100%;
    position:relative;
    transition: transform 0.5s;
    /*como os child elements fazem o render 3D */
    transform-style: preserve-3d;
    background-color: transparent;
  }
  
  .face {
    width:100%;
    height:100%;
    
    color:#FFF;
    line-height:15em;
    text-align:center;
    position:absolute;
    /*esconder ou mostrar a face escondida*/
    backface-visibility:hidden;
  }
  

  
  .back {
    transform: rotateY(180deg);
  }
  
  .scene:hover .card3d {
    transform: rotateY(180deg);
  }

}


.video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.video-container {



  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  text-align: center;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // max-width: 900px;
  }

  @media screen and (min-width: $screen-sm) {
    padding-top: 0px;
  }
}

.tool-tip-product {
  position: absolute;
  // force to read \n breakline on string
  white-space: pre-wrap;
  pointer-events: none;
  opacity: 0;

  .fundo-fx-pro {
    position: absolute;
    right: -196px;
    top: -20px;
  }

  .leg-product-group {
    position: absolute;
    left: 65px;
    top: -14px;
    padding: 3px 10px;
    background: #3DD6FE;
    border: 1px solid $primary;
    border-radius: 3px;

    p{
      margin: 0;
      font-weight: 700;
      font-size: 1.4rem;
      color: $primary;
      line-height: 17px;
      min-width: 160px;
      padding: 3px 0;
      text-align: left;
    }
  }
}

.tool-tip-product.abre-esq {
  .linhas-connect{
    position: relative;
    transform: scaleX(-1);
    right: 64px;
  }
  .leg-product-group {
    left: -242px;
  }

  .fundo-fx-pro {
    right: 160px;
  }
}


@keyframes flash-img {
  0%{opacity: 1;}
  49%{opacity: 1;}
  50%{opacity: 0;}
  99%{opacity: 0;}
}

#prototipo_img22 {
  animation: flash-img 6s infinite; 
}