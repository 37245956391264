/*
 *  Footer
 *  scss - imported on index.scss
 *
 */



.co-fi {
  position: absolute;
  top: -25px;
  color: #fff;
  font-size: 1.4rem;
}

.main-footer {
  position: relative;
  background-color: $blue-dry-d;
  margin-top: 150px;
  text-align: left;

  @media screen and (max-width: 575px) {
    margin-top: 120px;
  }

  .base-oval {
    position: absolute;
    width: 100%;
    height: 100px;
    border-radius: 50%;
    background: #005e81;
    top: -50px;
    z-index: -10;
  }

  .pingo-ele {
    position: absolute;
    top: -130px;
    left: 0;
    right: 0;
    margin: auto;
}

.logo-inv {
  margin-top: 80px;
  margin-bottom: 20px;
}

.logo-desc {
  max-width: 310px;
  margin-bottom: 25px;
  color: #fff;
  font-weight: 600;
  font-size: 2.6rem;
  letter-spacing: 1.32px;
  line-height: 33px;

  @media screen and (max-width: $screen-md) {
    max-width:100%;
  }
  @media screen and (min-width: 1500px) {
    max-width: 390px;
  }
}
.button-item {

  @include gradientBt;
  padding: 0 20px;
  height: 45px;
  border-radius: 22.5px;
  line-height: 24px;
  font-weight: 700;
  font-size: 2.1rem;
  color: #FFFFFF;
  border: none;
  transition: all .3s ease-out;
  &:hover {
    background:white;
    color: #005e81;
  }
  @media screen and (max-width: $screen-md) {
    margin-bottom:40px;
  }
}

.tit-menu-footer {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #3DD6FE;
  letter-spacing: 0.91px;
  @media screen and (max-width: $screen-sm) {
    margin-top:30px;
  }
}

.footer-nav {
  display: flex;
  list-style: none;
  flex-flow: column wrap;
  padding-left: 0;

  a{
    text-transform: lowercase;
    font-weight: 600;
    font-size: 1.6rem;
    color: #FFFFFF;
    letter-spacing: 0.77px;
    line-height: 33px;
    text-decoration: underline;
    cursor: pointer;
    transition: all .315s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      text-decoration-color:$blue-dry;
    }
  }
}

.footer-nav-adress {
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 1.6rem;
  color: #FFFFFF;
  letter-spacing: 0.77px;
  line-height: 32px;
}

.links {
  margin-top: 95px;
  padding-bottom: 95px;

  @media screen and (max-width: $screen-lg) {
    margin-top: 75px;
    padding-bottom: 75px;
  }
  @media screen and (max-width: $screen-md) {
    margin-top: 50px;
    padding-bottom: 50px;
  }


  .link1{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    @media screen and (max-width: $screen-sm) {
      margin-bottom: 30px;
    }
  }
  .link2{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    img{
      margin-right: 15px;
      max-width: 44px;
    }
  }
}

}

.linhas-footer {
  position: absolute;
  bottom: 0%;
  width: 115%;
  object-fit: cover;
  left: -118px;
  transform: rotate(41deg);
  opacity: .3;
  mix-blend-mode: luminosity;



  @media screen and (min-width: 320px) {
    transform: rotate(71deg)scale(1.5);
    width: 180%;
    bottom: 62px;

  }
  @media screen and (min-width: 768px) {
    transform: rotate(41deg);
    width: 115%;
    bottom: 0%;

  }

  @media screen and (min-width: $screen-xxl) {
    transform: rotate(41deg)scale(1.5)!important;
  }

}
.sub-footer {
  @include gradientBt;
  min-height: 100px;


  p {
    /* 2020 - Todos os dire: */
    font-weight: 600;
    font-size: 1.7rem;
    color: #FFFFFF;
    letter-spacing: 0.77px;
  }

  .flex-date {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 100px;

    @media screen and (max-width: $screen-md) {
      min-height: 40px;
      margin-top: 15px;
    }
  }

  .flex-poli {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    min-height: 100px;
    
    @media screen and (max-width: $screen-lg) {
      padding-right: 50px;
    }
    @media screen and (max-width: $screen-md) {
      min-height: 20px;
      padding-right: 15px;

    }
  }

}


#modal-pages-1 {

  .modal-content {
    clip-path: url(#mask-heal-item);
    // padding: 20px;
    //mix-in no global
    @include fade-in('450ms');
    border: none;
    border-radius: .9rem;



    .modal-header {
      padding-top: 50px;
      border: none;
      padding: 90px 45px 0 45px;


     @media screen and (min-width: $screen-md) {
      padding: 90px 40px 0 40px;
     }
     @media screen and (max-width: $screen-sm) {
      padding: 60px 30px 0 30px;
     }

      .close{
        position: relative;
        right: 50px;
        bottom: 80px;
        font-size: 9rem;
        color: #00ABEC;
        opacity: 1;
        padding: 0;
        transition: all .3s ease-out;
        &:hover {
          color: #005e81;
        }
        @media screen and (max-width: $screen-sm) {
          bottom: 68px;
         }
      }
    }
    .modal-body{
      padding: 0px 40px;

      @media screen and (max-width: $screen-sm) {
        padding: 0px 30px;
     }
     @media screen and (min-width: $screen-md) {
      padding: 0px 40px;
     }
    }
    .modal-footer {
      border: none;
      margin-top: 30px;
      justify-content: center;
      padding: 0 40px 70px 40px;
      @media screen and (max-width: $screen-sm) {
      padding: 0 30px 70px 30px;

     }
     @media screen and (min-width: $screen-md) {
      padding: 0 40px 70px 40px;

     }

      .btn {
        background: linear-gradient(-270deg, #00ABEC 0%, #53C8F4 100%);
        height: 45px;
        border-radius: 22.5px;
        line-height: 24px;
        font-weight: 700;
        font-size: 2.1rem;
        color: #FFFFFF;
        border: none;
        padding: 0px 25px;
        transition: all .3s ease-out;
        &:hover {
          background: #005e81;
        }
      }
    }

  }
  .tit-item {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.2;
  }
  .img-item { 
    clip-path: url(#mask-heal-item);
    margin-bottom: 30px;
      img {
        width: 100%;
        height: 50vh;
        max-height: 320px;
        object-fit: cover;
      }
  }

  .text-item {

     p {
      font-size: 1.7rem;
     }
     h2 {
      margin: 15px 0;
      font-size: 3rem;
     }
     li {
      font-size: 1.7rem;
     }
  }

  @media screen and (max-width: $screen-sm) {
    padding: 0 12px;
 }
}

div[data-uniid="1"] .modal-content {
  .modal-header  {
    padding-top: 180px!important;
  }
  .modal-footer  {
    padding-bottom: 180px!important;
  }
}


@keyframes scale-slow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.7);
  }
}

.intro-game {
  .mask-img-ball{
    margin: 0 auto;
    max-width: 350px;
    overflow:hidden;
    border-radius: 50%;
    border:8px solid $gold-dry;
    clip-path: circle(50% at 50% 50%);
    img{
      animation: scale-slow 20s ease-in infinite alternate;
    }
    @media screen and (max-width: $screen-lg) {
      max-width: 300px;
   }
    @media screen and (max-width: $screen-md) {
       max-width: 250px;
    }
    @media screen and (max-width: $screen-sm) {
      max-width: 210px;
   }
  }
  .txt-pergunta {
    font-size: 2.8rem!important;
    font-weight: 600;
    line-height: 34px;
    padding: 10px 7%;
    @media screen and (max-width: $screen-sm) {
      padding: 8px 0;
      font-size: 20px!important;
      line-height: 26px;
   }
  }
}

div[data-uniid="2"] {
  .modal-header {
    padding-top: 140px !important;
  }
  .modal-footer {
    padding-bottom: 140px !important;

  }
}

div[data-uniid="4"] {
  .modal-header{
    text-align: center;
    padding-left: 7%;;
  }
  .modal-footer{
    display: none;
  }
 #contained-modal-title-vcenter{
   width: 100%;
   h2 {
     font-size: 4.8rem;
     @media screen and (max-width: $screen-sm) {
      font-size: 3.8rem;
   }
   }
 }
 .sim-bt, .nao-bt{
  background: linear-gradient(-270deg,$gold-dry 0%, $gold-dry 100%);
  height: 45px;
  border-radius: 22.5px;
  line-height: 24px;
  font-weight: 700;
  font-size: 2.1rem;
  color: #FFFFFF;
  border: none;
  padding: 0px 25px;
  margin:0 10px;
  transition: all .3s ease-out;
  &:hover{
    background: $blue-dry-d;
  }
  @media screen and (max-width: $screen-sm) {
    font-size: 2.8rem;
    height: 35px;

  }
 }

 h1{
  margin-top: 10px;
  font-size: 3.3rem;
  color: $gold-dry;
 }

 .txt-resposta {
  font-size: 2.1rem!important;
  padding: 0 7%;
 }

 hr{
  max-width: 600px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media screen and (max-width: $screen-sm) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
 }

 .avancar-bt {
  background: $blue-dry-d;
  height: 35px;
  border-radius: 22.5px;
  line-height: 24px;
  font-weight: 500;
  font-size: 1.8rem;
  color: #FFFFFF;
  border: none;
  padding: 0px 20px 5px 20px;
  margin:0 10px;
  margin-bottom: 40px;
  transition: all .3s ease-out;
  &:hover{
    background: linear-gradient(-270deg, #00ABEC 0%, #53C8F4 100%);
  }
 }
}