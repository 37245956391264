/*
 *  How to USE
 *  scss - imported on index.scss
 *
 */

.use {
  position: relative;
  margin-top: 50px;

  .fundo-use{
    position: absolute;
    transform: translateX(-50%) translateY(-6%) scaleX(1.2) scaleY(1.1);
    z-index: -11;
    @media screen and (max-width: $screen-md) {
      transform: translateX(-50%) translateY(9%) scaleX(1.2) scaleY(1.24);
    }
    @media screen and (max-width: 575px) {
      transform: translateX(-50%) translateY(41%) scaleX(1.2) scaleY(1.88);
    }

  }
  .tit-use {
    font-weight: 800;
    font-size: 6rem;
    color: #FFFFFF;
    letter-spacing: 3px;
    text-align: center;
  }

  .sub-tit-use {
    font-weight: 600;
    font-size: 3rem;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 80px;
    @media screen and (max-width: 575px) {
      margin-bottom: 30px;
      line-height: 32px;
    }
  }

  .base-use-item {
    @media screen and (max-width:$screen-md) {
      margin-bottom: 30px;
   }
    p {
      font-weight: 600;
      font-size: 1.8rem;
      color: #FFFFFF;
      letter-spacing: 1px;
      text-align: center;
    }
    .img-how {
        min-height: 250px;
        @media screen and (max-width: $screen-md) {
          min-height: 230px;

        }
    }
  }
}
  

html[lang='de'] {

  .use {
    @media screen and (max-width: 575px) {
      background-color:$blue-dry;
    }
  }

  .fundo-use{
    @media screen and (max-width: $screen-lg) {
      transform: translateX(-50%) translateY(9%) scaleX(1.2) scaleY(1.34);
    }
    @media screen and (max-width: $screen-md) {
      transform: translateX(-50%) translateY(9%) scaleX(1.2) scaleY(1.54);
      
    }
    @media screen and (max-width: 575px) {
      display: none;
    }

    @media screen and (min-width: 400px) and (max-width: 500px ) {
      //transform: translateX(-50%) translateY(41%) scaleX(1.2) scaleY(2.38);
    }
    @media screen and (min-width: 992px) {
      transform: translateX(-50%) translateY(3.5%) scaleX(1.2) scaleY(1.05);
    }

  }
}