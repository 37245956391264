
/*
 *  CORES
 *  cores principais
 *
 */

$blue-dry: #00ABEC !default;
$blue-dry-d: #005E81 !default;
$blue-dry-l: #EDFAFF !default;
$gold-dry: #d7a429 !default;
$white-dry: #fff !default;

$blue-popup: #3DD6FE;

$blue-grad-1: #53C8F4 !default;

/* GRADIENT LISTS
============================================= */
$list-gdradient-1: $blue-dry 0%, $blue-grad-1 100%;
$list-gdradient-2: $blue-dry-l 4%, $blue-dry-l 85%;


//use:   @include gradient(left, $list-gdradient-1);
@mixin gradient($direction, $list) { 
    background: -webkit-linear-gradient($direction, $list);
    background: -moz-linear-gradient($direction, $list);
    background: -o-linear-gradient($direction, $list);
    background: linear-gradient($direction, $list);  
  }

//use:   @include gradientBt;
@mixin gradientBt { @include gradient(-270deg, $list-gdradient-1) };
@mixin gradientFundo { @include gradient(-180deg, $list-gdradient-2) };