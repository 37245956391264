/*
 *  Testimonials
 *  scss - imported on index.scss
 *
 */

.testimonials {
  position: relative;

  .tit-testimonials {
    text-align: center;
    font-weight: 800;
    font-size: 6rem;
    color: $primary;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 90px;
    text-transform: uppercase;
    @media screen and (max-width: 575px) {
      margin-top: 30px;
      font-size: 5rem;
    }
  }

  .sub-tit-testimonials {
    font-weight: 700;
    font-size: 3rem;
    color: $secondary;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 50px;
    @media screen and (max-width: 575px) {
      margin-bottom: 30px;
      line-height: 30px;
    }
  }

}

#testimonials-swiper {
  overflow: visible;

  .swiper-wrapper {
    padding-left: 0;
    padding-bottom: 80px;
  }     
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .testimonials-slide {
    display: flex;
    justify-content: center;
    padding: 0 80px;

    @media screen and (max-width: 400px) {
      padding: 0 40px;
    }

    .slide-wrapper {
      position: relative;
      max-width: 700px;
      background:$blue-dry-d;
      box-shadow: 0 67px 55px -22px rgba(63,64,65,0.22), 0 43px 39px -13px rgba(68,76,79,0.20);
      border-radius: 8px;
      padding: 40px 30px;
      @media screen and (max-width: 400px) {
        padding: 40px 20px;
      }
      @media screen and (min-width: 1500px) {
        max-width: 850px;
      }
    }
    .ball-ele {
      position: absolute;
      top: -15px;
      z-index: -1;
      animation: moveX infinite 10s ease-in-out;
    }
    .text-item {
      font-weight: 500;
      font-size: 2.1rem;
      color: #FFFFFF;
      letter-spacing: .5px;
      // text-transform: uppercase;
      line-height: 26px;

      @media screen and (min-width: 1500px) {
        line-height: 31px;
    }
    }
    .tit-item {
      font-weight: 600;
      font-size: 2rem;
      color: #3DD6FE;
      letter-spacing: .5px;
      margin-bottom: 0;
    }
    .aspas-esq {
      position: absolute;
      left: -100px;
      top: -20px;
      @media screen and (max-width: 400px) {
        left: -88px;
        transform: scale(.8);
      }
    }
    .aspas-dir {
      position: absolute;
      right: -100px;
      bottom: -50px;
      @media screen and (max-width: 400px) {
        right: -88px;
        transform: scale(.8);
      }
    }
  }
  .swiper-pagination {
    padding-top: 20px;
    span {
      opacity: 1;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $blue-dry;
    }
    span.swiper-pagination-bullet-active {
      background-color: $blue-dry-d;
    }
  }
}