/*
 *  BASE
 *  Import all helpers, core styles and components
 *
 */

@charset "utf-8/index";

/* Helpers: re-usable code (nothing here is compiled directly) */
@import "lib/_bootstrap-overrides";

/* Foundation: Normalise, resets, base styles & typography */

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import "lib/_colours";
@import "lib/_global";



/* Components */
@import "components/app/app";
@import "components/header/header";
@import "components/slider/slider";
@import "components/product/product";
@import "components/benefits/benefits";
@import "components/use/use";
@import "components/healt/healt";
@import "components/testimonials/testimonials";
@import "components/footer/footer";
@import "components/helpnav/helpnav";



